
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { apiEndpoint } from "@/mixin/apiEndpoint";
export default defineComponent({
  mixins:[apiEndpoint],
  name: "kt-drawer-target-change-summery",
  components: {},
  props: {
    data: { type: Object },
  },
  data() {
    return {
      formData: {
        id: "",
        entity_info_id: "",
        tranche_id: "",
        target_year_id: "",
        target_year: "",
        target_trainee_number: "",
      },
      oldData:"",
      newData:"",
      trainee_target:"",
      updateLog: [],
      tableColumn: [
        {
          name: "Tranche",
          key: "tranche_id",
        },
        {
          name: "Association",
          key: "entity_info_id",
        },
        {
          name: "Target Year Id",
          key: "target_year_id",
        },
        {
          name: "Target Year",
          key: "target_year",
        },
        {
          name: "Trainee Number",
          key: "target_trainee_number",
        },
      ],
      load: false,
      showChange: false,
    };
  },
  async created() {
    this.emitter.on("institute_target_change_summary", async (data) => {
      this.formData = data;
      console.log(data);
      await this.getUpdateLog(data.id);
    });
  },
  methods: {
    async getUpdateLog(id){
      await ApiService.get(this.VUE_APP_PENDING_LOG_TARGET_LIST_API+"?id="+id).then((response)=>{
        this.load = false;
        console.log(response);
        let data=response.data.data;
        if(data){
          this.newData=JSON.parse(data.form_data);
          console.log(this.newData);
        }
      }).catch((response)=>{
        this.load = false;
        console.log(response);
      })
    },
    viewChange() {
      if (this.showChange) {
        this.showChange = false;
      } else {
        this.showChange = true;
      }
    },
  },
});
